import Vue from 'vue'
import AgentEditToast from '@/components/agent/toast/AgentEditToast.vue'

let AgentEditToastConstructor = Vue.extend(AgentEditToast) // 构造函数
let instance // 实例对象
let seed = 1 // 计数

const AgentEditToastDialog = (options = {}) => {
    if (typeof options === 'string') {
        options = {
            agentId: null,
            type: 'save'
        }
    }
    let id = `agenttoast_${seed++}`
    instance = new AgentEditToastConstructor({
        data: options
    })
    instance.id = id
    instance.vm = instance.$mount()
    document.body.appendChild(instance.vm.$el)
    instance.vm.visible = true
    instance.dom = instance.vm.$el
    instance.dom.style.zIndex = 999 + seed
    return instance.vm
}

export default AgentEditToastDialog