<template>
    <el-dialog :title="title"
               :visible.sync="outerVisible"
               @close="close"
               width="75%">

        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="파트너정보1" name="partnerInfo1">
                <table class="table100">
                    <tr>
                        <th style="width: 14%">아이디</th>
                        <th style="width: 14%">닉네임</th>
                        <th style="width: 14%">비밀번호</th>
                        <th style="width: 14%">은행명</th>
                        <th style="width: 14%">계좌번호</th>
                        <th style="width: 14%">예금주</th>
                    </tr>
                    <tr>
                        <td>
                            <span v-if="mode == managerConst.MODE_EDIT">{{agent.account}}</span>
                            <el-input v-if="mode == managerConst.MODE_SAVE" size="mini"
                                      v-model="agent.account"></el-input>
                        </td>
                        <td>
                            <el-input size="mini"
                                      v-model="agent.nickname"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.passwd"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.bank"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.acNo"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.beneficiary"></el-input>
                        </td>

                    </tr>
                    <tr>
                        <th style="width: 14%">환전비번</th>
                        <th style="width: 14%">가입레벨</th>
                        <th style="width: 14%">가입그룹</th>
                        <th style="width: 14%">상태</th>
                        <th style="width: 14%">회원가입</th>
                        <th style="width: 14%">머니이동</th>
                    </tr>
                    <tr>
                        <td>
                            <el-input size="mini" v-model="agent.extractPasswd"></el-input>
                        </td>
                        <td>
                            <!--레벨-->
                            <el-select size="mini" v-model="agent.defaultUserRank" placeholder="레벨 선택"
                                       style="width: 80%">
                                <el-option v-for="rank in rankList" :value="rank.id" :label="rank.rankName">
                                    {{rank.rankName}}
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select size="mini" v-model="agent.defaultUserGroup" placeholder="그룹 선택"
                                       style="width: 80%">
                                <el-option v-for="group in groupList" :value="group.id" :label="group.groupName">
                                    {{group.groupName}}
                                </el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select size="mini" v-model="agent.status" placeholder="상태" style="width: 80%">
                                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                                <el-option :value="managerConst.DISABLE" label="블럭">블럭</el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select size="mini" v-model="agent.registable" placeholder="회원가입" style="width: 80%">
                                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                            </el-select>
                        </td>
                        <td>
                            <el-select size="mini" v-model="agent.moneymoveable" placeholder="머니이동"
                                       style="width: 80%">
                                <el-option :value="managerConst.ENABLE" label="정상">정상</el-option>
                                <el-option :value="managerConst.DISABLE" label="차단">차단</el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th style="width: 14%">보유머니</th>
                        <th style="width: 14%">손익금%</th>
                        <th style="width: 14%">(스포츠)베팅%</th>
                        <th style="width: 14%">(미니게임)베팅%</th>
                        <th style="width: 14%">(카지노)베팅%</th>
                        <th style="width: 14%">(슬롯)베팅%</th>
                    </tr>
                    <tr>
                        <td>{{agent.cash|comma}}</td>
                        <td>
                            <el-input size="mini" v-model="agent.rechargeExchangeProfit"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.sportsBetRollingProfit"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.leisureBetRollingProfit"></el-input>
                        </td>
                        <td>
                            <el-input size="mini" v-model="agent.casinoBetRollingProfit"></el-input>
                        </td>
                        <td colspan="">
                            <el-input size="mini" v-model="agent.slotBetRollingProfit"></el-input>
                        </td>
                    </tr>

                </table>
            </el-tab-pane>

            <el-tab-pane label="파트너정보2" v-if="mode === managerConst.MODE_EDIT" name="partnerInfo2">
                <h5>메모</h5>
                <el-input v-if="null != agent" type="textarea" v-model="agent.memo" rows="10"></el-input>
            </el-tab-pane>
        </el-tabs>

        <div slot="footer" class="dialog-footer">
            <el-button @click="save()">저장하기</el-button>
            <el-button @click="outerVisible = false">취소</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import agentConst from "../../../common/agent/agentConst";
    import {
        getAgentById,
        getGroupList2,
        getRankList2,
        saveAgent2,
        updateAgent2
    } from "../../../network/agent/commonRequest";

    export default {
        name: "AgentEditToast",
        components: {},
        data() {
            return {
                title: '',
                agentId: null, //
                agent: {},
                closed: false,
                outerVisible: false,
                managerConst: agentConst,
                mode: agentConst.MODE_SAVE, //save:등록 ,edit:수정,
                partnerList: [],
                rankList: [],
                groupList: [],
                activeName: 'partnerInfo1',

            }
        },
        methods: {
            search(){

            },
            close() {
                this.agent = {}
                this.agentId = null
                this.closed = true
                this.outerVisible = false
            },
            destroyElement() {
                this.$destroy()
                this.$el.parentNode.removeChild(this.$el)
            },
            save() {
                if (this.mode === agentConst.MODE_SAVE) {
                    this.agent.parentId = this.agentId
                    saveAgent2(this.agent).then(res => {
                        if (res.data.success) {
                            this.$message({
                                showClose: false,
                                message: '파트너 등록이 완료되였습니다',
                                duration: 1500,
                                type: 'success'
                            });
                            this.outerVisible = false
                            this.$bus.$emit('agentEdited2')
                        } else {
                            this.$message({
                                showClose: false,
                                message: res.data.msg,
                                duration: 1500,
                                type: 'error'
                            });
                        }
                    })
                }
                if (this.mode === agentConst.MODE_EDIT) {
                    updateAgent2(this.agent).then(res => {
                        if (res.data.success) {
                            this.$message({
                                showClose: false,
                                message: '에이전트 정보 수정이 완료되였습니다',
                                duration: 1500,
                                type: 'success'
                            });
                            this.outerVisible = false
                            this.$bus.$emit('agentEdited2')
                        } else {
                            this.$message({
                                showClose: false,
                                message: res.data.msg,
                                duration: 1500,
                                type: 'error'
                            });
                        }
                    })
                }
            },

            getAgentById() {
                getAgentById(this.agentId).then(res => {
                    this.agent = res.data.data
                    this.title = '에이전트 [' + this.agent.nickname + '] 님 상세정보'
                })
            },

            setStartDate() {
                this.startDate = this.$moment(this.startDate).format('yyyy-MM-DD');
            },
            setEndDate() {
                this.endDate = this.$moment(this.endDate).format('yyyy-MM-DD')
            },


        },
        created() {
            this.outerVisible = true
            getRankList2({'status': agentConst.ENABLE}, 1, 100).then(res => {
                this.rankList = res.data.data
            })
            getGroupList2({'status': agentConst.ENABLE}, 1, 100).then(res => {
                this.groupList = res.data.data
            })
            if (this.mode === agentConst.MODE_EDIT) {
                this.getAgentById()
            } else {
                this.agent.status = agentConst.ENABLE;
                this.agent.registable = agentConst.ENABLE;
                this.agent.moneymoveable = agentConst.DISABLE;
                let parentId = this.agentId == agentConst.Parnter.PATNER_GENERALAGENT_NOID ? agentConst.Parnter.PATNER_GENERALAGENT_NOID : this.agentId;
                this.agent.parentId = parentId
            }
        },
        mounted() {

        },
        watch: {
            closed(newVal) {
                if (newVal) {
                    this.outerVisible = false
                    this.destroyElement()
                }
            }
        },

    }
</script>

<style scoped>
    .table100 td {
        padding: 5px;
        background-color: #cfcfd1 !important;
    }

    .table100 th {
        width: 20%;
    }

    .table100 .el-input__inner {
        width: 80% !important;
    }

    .table_userstatisc {
        width: 100%;
        margin-bottom: 5px;
    }

    .table_userstatisc th {
        width: 14%;
    }

    .table_userstatisc td {
        background-color: #e8e8e8 !important;
        width: 14%;
    }

</style>